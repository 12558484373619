import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import styles from "./Navbar.module.css";
import cn from "classnames";

import CartButton from "components/Cart/CartButton";
import { P } from "common/P/P";

import { ReactComponent as MailIcon } from "common/Icons/mail.svg";
import { ReactComponent as HelpIcon } from "common/Icons/help-circle.svg";
import { ReactComponent as Logo } from "common/Icons/Logo/AI.Based.svg";
import { ReactComponent as WishlistIcon } from "common/Icons/heart.svg";
import { routeConstants } from "routes";
import HamburgerMenu from "./HamburgerMenu";
import Cart from "components/Cart/Cart";
import LoginProfileButton from "./LoginProfileButton";
import AuthWrapper from "pages/auth/AuthWrapper/AuthWrapper";

const Navbar: React.FC = ({ handleNavigation }: any): JSX.Element => {
	const [cartOpen, setCartOpen] = useState(false);

	const location = useLocation();

	useEffect(() => {
		setCartOpen(false);
	}, [location.pathname]);

	const recipient = "info@aiprintable.com";
	const mailtoLink = `mailto:${recipient}`;

	return (
		<header>
			<div className={cn(styles.firstrow)}>
				<a
					href={mailtoLink}
					style={{
						textDecoration: "none",
						color: "inherit",
						cursor: "pointer",
					}}
					target="_blank"
				>
					<div className={cn(styles.iconlabel)}>
						<MailIcon />
						<P size="b2medium" style={{ cursor: "pointer" }}>
							{recipient}
						</P>
					</div>
				</a>
				<div className={cn(styles.iconlabelright)}>
					<div
						onClick={() => handleNavigation(routeConstants.help_centre.route)}
						className={styles.helpcontainer}
					>
						<HelpIcon />
						<P size="b2medium">Help</P>
					</div>

					<P
						size="b2medium"
						className={cn(styles.links)}
						style={{ width: "max-content" }}
						onClick={() => handleNavigation(routeConstants.about_us.route)}
					>
						About us
					</P>
				</div>
			</div>
			<div className={cn(styles.secondrow)}>
				<div onClick={() => handleNavigation(routeConstants.root.route)}>
					<Logo className={cn(styles.logo, styles.links)} />
				</div>
				<div className={cn(styles.vl)}></div>
				<div className={cn(styles.pages)}>
					<P
						size="b1medium"
						className={cn(styles.links)}
						onClick={() => handleNavigation(routeConstants.generate.route)}
					>
						Create
					</P>

					<Link to={routeConstants.design.route}>
						<P size="b1medium" className={cn(styles.links)}>
							Designs
						</P>
					</Link>

					<Link to={routeConstants.ourProducts.route}>
						<P size="b1medium" className={cn(styles.links)}>
							Products
						</P>
					</Link>

					<Link to={routeConstants.shop.route}>
						<P size="b1medium" className={cn(styles.links)}>
							Shop
						</P>
					</Link>

					{/* <P
						size="b1medium"
						className={cn(styles.links)}
						onClick={() => handleNavigation(routeConstants.blog.route)}
					>
						Blogs
					</P> */}
				</div>

				<LoginProfileButton />

				{/*<div
					className={cn(styles.profiledrop)}
					onClick={() => setAuthOpen(!authOpen)}
				>
					<div className={cn(styles.profile)}>
						<span className={cn(styles.profilebg)}>
							<UserIcon className={cn(styles.usericon)} />
						</span>
						<P size="b2medium">Join | Login</P>
					</div>
				</div>*/}
				{/*{authOpen && (
					<AuthWrapper />
				)}*/}

				<AuthWrapper />
				<div className={cn(styles.shopping)}>
					<div
						onClick={() =>
							handleNavigation(routeConstants.generated_image_wishlist.route)
						}
						className={cn(styles.wishlistbutton)}
					>
						<WishlistIcon />
					</div>
					<div style={{ position: "relative" }}>
						<CartButton cartOpen={cartOpen} setCartOpen={setCartOpen} />
						{cartOpen && <Cart handleNavigation={handleNavigation} />}
					</div>
				</div>
			</div>
		</header>
	);
};

const MobileNavbar: React.FC = ({ handleNavigation }: any): JSX.Element => {
	const [cartOpen, setCartOpen] = useState(false);

	const location = useLocation();

	useEffect(() => {
		setCartOpen(false);
	}, [location.pathname]);

	return (
		<div className={cn(styles.mobilenavbar)}>
			<HamburgerMenu handleNavigation={handleNavigation} />
			<div className={cn(styles.mobilesecondrow)}>
				<LoginProfileButton />

				<AuthWrapper />

				<div className={cn(styles.mobileshopping)}>
					<div
						onClick={() =>
							handleNavigation(routeConstants.generated_image_wishlist.route)
						}
						className={cn(styles.wishlistbutton)}
					>
						<WishlistIcon />
					</div>
					<div style={{ position: "relative" }}>
						<CartButton cartOpen={cartOpen} setCartOpen={setCartOpen} />
						{cartOpen && <Cart handleNavigation={handleNavigation} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export const responsiveNavbar =
	window.innerWidth <= 1300 ? <MobileNavbar /> : <Navbar />;

export default Navbar;
