import { authConstants } from "airedux/constants";
import { authService } from "airedux/services";
import { toastMessage } from "airedux/helpers";
import { alertActions } from ".";

const logout = (withToastMessage = true) => {
	authService.logout();
	return { type: authConstants.LOGOUT };
};

export const register_rp_acc = (
	registerToken: string,
	payload: any,
	set_loading: any,
	withToastMessage = true
) => {
	return (dispatch: any) => {
		authService.register_rp_acc(registerToken, payload).then(
			(response: any) => {
				set_loading(false);
				response.success === true &&
					dispatch(
						success({
							...response,
							auth_username: response.email,
							auth_password: response.password,
						})
					);
				withToastMessage &&
					toastMessage({
						type: response.success === true ? "success" : "error",
						message: response.message,
						position: "center-top",
					});
			},
			(error: any) => {
				set_loading(false);
				dispatch(failure(error.toString()));
				withToastMessage &&
					toastMessage({
						type: "error",
						message: error.toString(),
						position: "center-top",
					});
				dispatch(alertActions.error(error.toString()));
			}
		);
	};

	function success(user: any) {
		return { type: authConstants.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: any) {
		return { type: authConstants.LOGIN_FAILURE, payload: error };
	}
};

const googleAuth = (payload: any, set_loading: any) => {
	return (dispatch: any) => {
		dispatch(request(payload));
		authService.googleAuth(payload).then(
			(response: any) => {
				set_loading(false);
				response.success === true && dispatch(success(response));
			},
			(error: any) => {
				set_loading(false);
				dispatch(failure(error.toString()));
				dispatch(alertActions.error(error.toString()));
			}
		);
	};

	function request(user: any) {
		return { type: authConstants.LOGIN_REQUEST, payload: user };
	}
	function success(user: any) {
		return { type: authConstants.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: any) {
		return { type: authConstants.LOGIN_FAILURE, payload: error };
	}
};

export const verifyAuth = (payload: any) => {
	return (dispatch: any) => {
		authService.verifyAuth(payload).then(
			(response: any) => {
				response.success === true && dispatch(success(response));
			},
			(error: any) => {
				dispatch(failure(error.toString()));
				dispatch(alertActions.error(error.toString()));
			}
		);
	};

	function success(user: any) {
		return { type: authConstants.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: any) {
		return { type: authConstants.LOGIN_FAILURE, payload: error };
	}
};
const login = (username: string, password: string) => {
	return (dispatch: any) => {
		dispatch(request({ username }));
		authService.login(username, password).then(
			(response: any) => {
				console.log("Response", response);
				dispatch(
					success({
						...response,
						auth_username: username,
						auth_password: password,
					})
				);

				toastMessage({
					type: "success",
					message: `${username}, You have successfully logged in!`,
				});
			},
			(err: any) => {
				console.error("Unhandled error in login:", err);
				dispatch(failure(err.toString()));
				toastMessage({ type: "error", message: err.toString() });
				dispatch(alertActions.error(err.toString()));
			}
		);
	};

	function request(user: any) {
		return { type: authConstants.LOGIN_REQUEST, payload: user };
	}
	function success(user: any) {
		return { type: authConstants.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: any) {
		return { type: authConstants.LOGIN_FAILURE, payload: error };
	}
};

export const reset_password = (
	registerToken: string,
	payload: any,
	set_loading: any,
	withToastMessage = true
) => {
	return (dispatch: any) => {
		authService.reset_password(registerToken, payload).then(
			(response: any) => {
				set_loading(false);
				response.success === true &&
					dispatch(
						success({
							...response,
							auth_username: payload.email,
							auth_password: payload.password,
						})
					);
				withToastMessage &&
					toastMessage({
						type: response.success === true ? "success" : "error",
						message: response.message,
						position: "center-top",
					});
			},
			(error: any) => {
				set_loading(false);
				dispatch(failure(error.toString()));
				withToastMessage &&
					toastMessage({
						type: "error",
						message: error.toString(),
						position: "center-top",
					});
				dispatch(alertActions.error(error.toString()));
			}
		);
	};

	function success(user: any) {
		return { type: authConstants.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: any) {
		return { type: authConstants.LOGIN_FAILURE, payload: error };
	}
};

export const profileUpdate = (data: any) => ({
	type: authConstants.PROFILE_UPDATE,
	payload: data,
});

export const authActions = {
	login,
	logout,
	register_rp_acc,
	reset_password,
	googleAuth,
	verifyAuth,
};
