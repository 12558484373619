/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";

import styles from "./Auth.module.css";
import cn from "classnames";

import { Modal } from "common/Modal/Modal";
import { Button, P } from "common";
import { authService } from "airedux/services";
import { useDispatch } from "react-redux";
import { authActions } from "airedux/actions";
import { toastMessage } from "airedux/helpers";
import { routeConstants } from "routes";
import { useNavigate } from "react-router-dom";
import Spinner from "modules/loaders/Spinner/Spinner";
interface IPropsLogin {
	onStageChange: any;
	validationData: any;
	handleValidationData: any;
	register_mode?: boolean;
}

const AuthVerification: React.FC<IPropsLogin> = ({
	onStageChange,
	validationData,
	handleValidationData,
	register_mode = false,
}) => {
	const [codeAttempts, set_codeAttempts] = useState(0);
	const retryTimeoutSeconds = 60;
	const [canRetrySend, set_canRetrySend] = useState(false);
	const [retryClicked, set_retryClicked] = useState(false);
	const [loading, set_loading] = useState(false);

	const email =
		register_mode === true
			? validationData.credentials.email
			: validationData.credentials;

	useEffect(() => {
		if (!canRetrySend) {
			setTimeout(() => {
				set_canRetrySend(true);
			}, retryTimeoutSeconds * 1000);
		}
		set_retryClicked(false);
	}, [canRetrySend, retryClicked]);

	const handleResendCode = () => {
		canRetrySend && set_retryClicked(true);
		canRetrySend &&
			authService.requestAuth(email).then(
				(response: any) => {
					response.success === true
						? handleSuccessRetry()
						: toastMessage({
								type: "success",
								message: response.message,
								position: "center-top",
						  });
				},
				(error: any) =>
					toastMessage({
						type: "error",
						message: error,
						position: "center-top",
					})
			);
	};

	const handleSuccessRetry = () => {
		toastMessage({
			type: "success",
			message: "Request sent",
			position: "center-top",
		});
		set_canRetrySend(false);
	};
	useEffect(() => {
		codeAttempts >= 3 &&
			toastMessage({
				type: "error",
				message: "Attempts exceeded",
				position: "center-top",
			}) &&
			onStageChange(1);
	}, [codeAttempts, onStageChange]);

	const [boxes_list, set_boxes_list] = useState([
		{ id: 1, name: "opt1", value: "" },
		{ id: 2, name: "opt2", value: "" },
		{ id: 3, name: "opt3", value: "" },
		{ id: 4, name: "opt4", value: "" },
		{ id: 5, name: "opt5", value: "" },
	]);

	const [verificationCode, setVerificationCode] = useState("");
	useEffect(() => {
		verificationCode.length === 5 && handleSubmit();
	}, [verificationCode]);
	const handleVerificationCodeChange = async (e: any, id: any, value?: any) => {
		const updating_value = e.target.value;
		const updatedList: any = updateValueInList(boxes_list, id, updating_value);
		await set_boxes_list(updatedList);
		await setVerificationCode(
			updatedList.map(({ value }: any) => value).join("")
		);
		if (
			e.key === "Delete" ||
			e.key === "Backspace" ||
			updating_value.length < 1
		) {
			//console.log("");
		} else {
			const next = e.target.tabIndex;
			if (next < 5) {
				e.target.form.elements[next].focus();
			}
		}
	};

	const updateValueInList = (arr: any, id: any, value: string) => {
		const idx = arr.findIndex((item: any) => item.id === id);
		const item = { ...arr[idx], value: value };
		return [...arr.slice(0, idx), item, ...arr.slice(idx + 1)];
	};
	const handleRemoval = (e: any) => {
		if (e.key === "Delete" || e.key === "Backspace") {
			const next = e.target.tabIndex - 2;
			if (next > -1) {
				e.target.form.elements[next].focus();
			}
		}
	};

	const handleSubmit = (e?: any) => {
		const payload = {
			verify_code: verificationCode,
			email: email,
		};
		authService.verifyAuth(payload).then(
			(response: any) => {
				response.success === true
					? handleSuccess(response)
					: toastMessage({
							type: "success",
							message: response.message,
							position: "center-top",
					  });
			},
			(error: any) =>
				toastMessage({
					type: "error",
					message: error,
					position: "center-top",
				})
		);
		set_codeAttempts(codeAttempts + 1);
		e && e.preventDefault();
	};

	const dispatch = useDispatch();

	const handleSuccess = (response: any) => {
		toastMessage({
			type: "success",
			message: response.message,
			position: "center-top",
		});

		if (register_mode) {
			set_loading(true);
			dispatch(
				authActions.register_rp_acc(
					response.data.token,
					validationData.credentials,
					set_loading
				)
			);
		} else {
			handleValidationData({
				...validationData,
				responseMessage: response.message,
				registerToken: response.data && response.data.token,
			});
			onStageChange(3);
		}
	};

	const navigate = useNavigate();
	return (
		<>
			<Modal
				title="Please check your email"
				openModal={true}
				setOpenModal={(e: any) =>
					e === false && navigate(`${routeConstants.root.route}`)
				}
				modalClass={true}
				button={
					!loading ? (
						<div className={cn(styles.twocol)}>
							<Button
								appearance="ghost-white"
								className={cn(styles.buttoncomponent)}
								onClick={() => navigate(`${routeConstants.root.route}`)}
							>
								Cancel
							</Button>

							<Button
								appearance="black"
								className={cn(styles.buttoncomponent)}
								onClick={handleSubmit}
							>
								Verify
							</Button>
						</div>
					) : (
						<Spinner />
					)
				}
			>
				<form
					onSubmit={handleSubmit}
					className="grid w-full gap-4 place-items-center"
				>
					<div className={cn(styles.logincontent)}>
						<P size="b1medium">We’ve sent a code to {email}</P>
						<div className={cn(styles.verificationdiv)}>
							{boxes_list.map(({ id, name, value }: any, idx: number) => (
								<input
									key={idx}
									name={name}
									autoFocus={id === 1 && true}
									type="text"
									autoComplete="off"
									className={cn(styles.verificationinputs)}
									onChange={(e) => handleVerificationCodeChange(e, id)}
									value={value}
									tabIndex={id}
									maxLength={1}
									onFocus={(e) => {
										e.target.value = "";
										handleVerificationCodeChange(e, id);
									}}
									onKeyUp={(e) => {
										handleRemoval(e);
									}}
								/>
							))}
						</div>
						<div className={cn(styles.retry)}>
							<P size="b1underline" className={cn(styles.passive)}>
								Didn’t get a code?
							</P>
							<P
								size="b1medium"
								className={`${cn(styles.resend)} 
							${canRetrySend ? "" : "hidden"}
								`}
								onClick={() => (canRetrySend ? handleResendCode() : {})}
							>
								Click to resend
							</P>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default AuthVerification;
