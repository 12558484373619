import React from "react";
import { Routes, Route } from "react-router-dom";

import { routeConstants } from "routes/routeConstants";
// import { UserRoutes } from "navigation";
// import { routeConstants } from "navigation";

const MainPage = React.lazy(() => import("pages/MainPage/MainPage"));
const GeneratePage = React.lazy(
	() => import("pages/GeneratePage/GeneratePage")
);
const GeneratedProductPage = React.lazy(
	() => import("pages/GeneratedProductPage/GeneratedProductPage")
);
const ProductPage = React.lazy(() => import("pages/ProductPage/ProductPage"));
const ProductDetailsPage = React.lazy(
	() => import("pages/ProductDetailsPage/ProductDetailsPage")
);
const BlogPage = React.lazy(() => import("pages/BlogPage/BlogPage"));
const BlogDetailsPage = React.lazy(
	() => import("pages/BlogDetailsPage/BlogDetailsPage")
);
const WishlistPage = React.lazy(
	() => import("pages/WishlistPage/WishlistPage")
);
const DesignPage = React.lazy(() => import("pages/DesignPage/DesignPage"));
const CheckoutPage = React.lazy(
	() => import("pages/CheckoutPage/CheckoutPage")
);
const ShippingAddressPage = React.lazy(
	() => import("pages/ShippingAddress/ShippingAddressPage")
);
const PaymentCardPage = React.lazy(
	() => import("pages/PaymentCardPage/PaymentCardPage")
);
const ProfileInfoPage = React.lazy(
	() => import("pages/ProfileInfoPage/ProfileInfoPage")
);
const ContactUsPage = React.lazy(
	() => import("pages/ContactUsPage/ContactUsPage")
);

// import PrivateRoute from "routes/PrivateRoute/PrivateRoute";

import NotFoundPage from "pages/errors/404/NotFoundPage";
import CategoryFilterPage from "pages/CategoryFilterPage/CategoryFilterPage";
import Spinner from "modules/loaders/Spinner/Spinner";
import { LogoutPage } from "pages/auth";
import CheckoutResultPage from "pages/CheckoutResultPage/CheckoutResultPage";
import PrivacyStatementPage from "pages/PrivacyStatementPage/PrivacyStatementPage";
import TermsConditionsPage from "pages/TermsConditionsPage/TermsConditionsPage";
import AboutUsPage from "pages/AboutUsPage/AboutUsPage";
import HelpCentrePage from "pages/HelpCentrePage/HelpCentrePage";
import ProductPageWrapper from "pages/ProductPage/ProductPageWrapper";
import ProductDetailsWrapper from "pages/ProductDetailsPage/ProductDetailsWrapper";
import OurProducts from "pages/OurProducts/OurProducts";
import ShopPage from "pages/ShopPage/ShopPage";
import AffiliatePage from "pages/AffiliatePage/AffiliatePage";

// import { NotFoundPage } from "pages/error";

const AppRoutes: React.FC = (): JSX.Element => {
	return (
		<React.Suspense fallback={<Spinner />}>
			<Routes>
				<Route path={routeConstants.root.route} element={<MainPage />} />
				<Route
					path={routeConstants.generate.route}
					element={<GeneratePage />}
				/>
				<Route
					path={routeConstants.generated_product.route}
					element={<GeneratedProductPage />}
				/>
				{/* <Route
					path={`${routeConstants.product_page.route}/:id`}
					element={<ProductPage />}
				/> */}

				<Route
					path={`${routeConstants.client_product_page.route}/:id`}
					element={<ProductPageWrapper storeType="client" />}
				/>
				<Route
					path={`${routeConstants.admin_product_page.route}/:id`}
					element={<ProductPageWrapper storeType="admin" />}
				/>

				{/* <Route
					path={`${routeConstants.product_details.route}/:id`}
					element={<ProductDetailsPage />}
				/> */}

				<Route
					path={`${routeConstants.client_product_details.route}/:id`}
					element={<ProductDetailsWrapper storeType="client" />}
				/>
				<Route
					path={`${routeConstants.shop_product_details.route}/:id`}
					element={<ProductDetailsWrapper storeType="shop" />}
				/>

				<Route
					path={routeConstants.ourProducts.route}
					element={<OurProducts />}
				/>

				<Route path={routeConstants.shop.route} element={<ShopPage />} />

				<Route path={routeConstants.blog.route} element={<BlogPage />} />
				<Route
					path={`${routeConstants.blog_details.route}/:id`}
					element={<BlogDetailsPage />}
				/>

				<Route
					path={`${routeConstants.category_filter.route}/:id`}
					element={<CategoryFilterPage />}
				/>

				<Route
					path={routeConstants.generated_image_wishlist.route}
					element={<WishlistPage />}
				/>

				<Route
					path={routeConstants.generated_product_wishlist.route}
					element={<WishlistPage />}
				/>

				<Route path={routeConstants.design.route} element={<DesignPage />} />

				<Route
					path={routeConstants.checkout.route}
					element={<CheckoutPage />}
				/>
				<Route
					path={`${routeConstants.checkout_result.route}`}
					element={<CheckoutResultPage />}
				/>

				<Route
					path={routeConstants.shippingaddress.route}
					element={<ShippingAddressPage />}
				/>

				<Route
					path={routeConstants.paymentcard.route}
					element={<PaymentCardPage />}
				/>

				<Route
					path={routeConstants.my_account.route}
					element={<ProfileInfoPage />}
				/>

				<Route
					path={routeConstants.my_orders.route}
					element={<ProfileInfoPage />}
				/>

				<Route
					path={routeConstants.my_address.route}
					element={<ProfileInfoPage />}
				/>

				<Route
					path={routeConstants.userGeneratedImages.route}
					element={<ProfileInfoPage />}
				/>

				<Route
					path={routeConstants.my_payment_cards.route}
					element={<ProfileInfoPage />}
				/>

				<Route
					path={routeConstants.contact_us.route}
					element={<ContactUsPage />}
				/>

				<Route
					path={routeConstants.privacy_statement.route}
					element={<PrivacyStatementPage />}
				/>
				<Route
					path={routeConstants.terms_conditions.route}
					element={<TermsConditionsPage />}
				/>

				<Route path={routeConstants.about_us.route} element={<AboutUsPage />} />
				<Route
					path={routeConstants.help_centre.route}
					element={<HelpCentrePage />}
				/>

				<Route
					path={routeConstants.affiliate.route}
					element={<AffiliatePage />}
				/>

				<Route path={routeConstants.logout.route} element={<LogoutPage />} />

				<Route path="*" element={<NotFoundPage />} />
				{/* <Route element={<NotFoundPage />} /> */}
			</Routes>
		</React.Suspense>
	);
};

export default AppRoutes;
