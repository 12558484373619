import React from "react";

import { ButtonProps } from "./Button.props";
import styles from "./Button.module.css";
import cn from "classnames";

export const Button = ({
	children,
	appearance,
	icon = "none",
	className,
	...props
}: ButtonProps): JSX.Element => {
	return (
		<button
			className={cn(styles.button, className, {
				[styles.black]: appearance === "black",
				[styles.white]: appearance === "white",
				[styles.gray]: appearance === "gray",
				[styles.ghostwhite]: appearance === "ghost-white",
				[styles.ghostblack]: appearance === "ghost-black",
				[styles.dashed]: appearance === "dashed",
				[styles.disabled]: appearance === "disabled",
				[styles.hashtag]: appearance === "hashtag",
				[styles.danger]: appearance === "danger",
				[styles.passive]: appearance === "passive",
			})}
			{...props}
		>
			{icon !== "none" && <>{icon}</>}
			{children}
		</button>
	);
};
