import React, { useEffect, useState } from "react";

import { ProductImageItemProps } from "./ProductImageItem.props";
import styles from "./ProductImageItem.module.css";
import cn from "classnames";
import { P } from "common/P/P";
import WishlistButton from "components/WishlistButton/WishlistButton";
import Spinner from "modules/loaders/Spinner/Spinner";

export const ProductImageItem = ({
	desc = false,
	className,
	src,
	alt,
	children,
	center,
	wish,
	id,
	data_type = "resource",
	loading,
	...props
}: ProductImageItemProps): JSX.Element => {
	const [randomMessage, setRandomMessage] = useState("");

	const data = [
		"Creating your unique masterpiece...",
		"Generating your one-of-a-kind design...",
		"Bringing your vision to life...",
		"Crafting your personalized artwork...",
		"Generating the perfect artwork just for you...",
		"Transforming your ideas into stunning visuals...",
		"Loading AI creativity...",
	];

	useEffect(() => {
		const interval = setInterval(() => {
			const randomIndex = Math.floor(Math.random() * data.length);
			setRandomMessage(data[randomIndex]);
		}, 3000);

		return () => clearInterval(interval);
	}, []);
	return (
		<div className={cn(styles.productimage, className)} {...props}>
			<img src={src} className={cn(styles.image)} alt={alt} loading="lazy" />
			{desc && (
				<div className={cn(center ? styles.centerdesc : styles.description)}>
					<P size="b1medium">{children}</P>
				</div>
			)}
			{wish && <WishlistButton data_type={data_type} id={id} />}
			{loading && (
				<div className={styles.spinnercontainer}>
					<P size="b2regular" className={cn(styles.spinnertext)}>
						{randomMessage}
					</P>
					<Spinner />
				</div>
			)}
		</div>
	);
};
