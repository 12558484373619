import React from "react";
import { connect } from "react-redux";
import { getVariantTotalCount } from "airedux/selectors";
import { ErrorBoundary } from "modules/errors";

import styles from "./Cart.module.css";
import cn from "classnames";

import { ReactComponent as ShopCartIcon } from "common/Icons/shopping-cart.svg";

interface IPropsCartButton {
	cartOpen?: any;
	setCartOpen?: any;
	totalCount?: any;
	totalPrice?: any;
}

const CartButton: React.FC<IPropsCartButton> = ({
	cartOpen,
	setCartOpen,
	totalCount,
	totalPrice,
	...props
}): JSX.Element => {
	return (
		<div
			className={cn(styles.container)}
			{...props}
			onClick={() => setCartOpen(!cartOpen)}
		>
			<ShopCartIcon />
			{/* <span className={cn(styles.cartquantity)}>{totalCount}</span> */}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const totalData = getVariantTotalCount(state);
	return {
		totalCount: totalData.totalCount,
		totalPrice: totalData.totalPrice,
	};
};

export default connect(mapStateToProps)(CartButton);
