import React, { useEffect, useState } from "react";

import styles from "./AboutUsPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { Htag, P } from "common";
import ReactGA from "react-ga";

const AboutUsPage: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className={cn(styles.aboutuspage)}>
			<SubNavbar disabled="Home /" active="About us" pageName="About us" />
			<div className={cn(styles.container)}>
				<Htag tag="h2" className={styles.headstyle}>
					About us
				</Htag>
				<P size="p1" className={styles.textstyle}>
					Welcome to AI Printable ! We're an innovative e-commerce platform that
					makes personalized gift-giving a breeze. Our AI-powered technology
					generates unique images that you can easily visualize on products like
					canvas prints, t-shirts, and more.
				</P>
				<P size="p1" className={styles.textstyle}>
					Creating custom gifts has never been so simple. With just a few
					clicks, our AI turns your ideas into captivating designs, ready to be
					showcased on high-quality products. No design skills? No problem! We
					handle production and shipping, so all you need to do is enjoy the
					creative process.
				</P>
				<P size="p1" className={styles.textstyle}>
					Join us in revolutionizing the way we express ourselves and celebrate
					life's special moments. Together, we'll create and share one-of-a-kind
					gifts that truly matter.
				</P>
			</div>
		</div>
	);
};

export default AboutUsPage;
