import React, { useEffect, useState } from "react";

import { responsiveNavbar } from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import AppRoutes from "routes/AppRoutes/AppRoutes";
import { ToastContainer, Zoom } from "react-toastify";
import { connect } from "react-redux";
import {
	fetchResources,
	fetchCategories,
	fetchWishlist,
	fetchVariantById,
	fetchResourceById,
} from "airedux/actions";
import { getResources } from "airedux/selectors";
import WithNavigationPrompt from "HOC/WithNavigationPrompt";
import { routeConstants } from "routes";

const MainWrapper: React.FC = ({
	loggedIn,
	wishlist,
	resources,
	fetchResources,
	fetchCategories,
	fetchWishlist,
	fetchVariantById,
	fetchResourceById,
}: any): JSX.Element => {
	useEffect(() => {
		// fetchResources("", "admin");
		fetchCategories();
		loggedIn && fetchWishlist();
	}, []);
	const [resource_fetchDone, set_resource_fetchDone] = useState(false);
	const [wishlist_fetchDone, set_wishlist_fetchDone] = useState(false);
	const [wish_variant_fetchDone, set_wish_variant_fetchDone] = useState(false);

	useEffect(() => {
		if (!wish_variant_fetchDone) {
			wishlist.variant.map((id: any) => {
				fetchVariantById(id);
				set_wish_variant_fetchDone(true);
			});
		}
		// if (!wishlist_fetchDone) {
		// 	wishlist.resource.map((id: any) => {
		// 		fetchResourceById(id, "client");
		// 		set_wishlist_fetchDone(true);
		// 	});
		// }
		// if (!resource_fetchDone) {
		// 	resources.map((resource: any) => {
		// 		// console.log(resource);
		// 		fetchResourceById(resource.id, "admin");
		// 		set_resource_fetchDone(true);
		// 	});
		// }
	}, [
		wishlist,
		resources,
		// resource_fetchDone,
		// wishlist_fetchDone,
		wish_variant_fetchDone,
	]);

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				limit={3}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				transition={Zoom}
			/>
			<WithNavigationPrompt
				shouldPromptOnRoute={routeConstants.generated_product.route}
			>
				{responsiveNavbar}
				<AppRoutes />
				<Footer />
			</WithNavigationPrompt>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
	wishlist: state.wishlist,
	resources: getResources(state),
});

const mapDispatchToProps = {
	fetchCategories,
	// fetchResources,
	fetchWishlist,
	fetchVariantById,
	// fetchResourceById,
};
export default connect(mapStateToProps, mapDispatchToProps)(MainWrapper);
