/* eslint-disable no-case-declarations */
import { variantConstants } from "airedux/constants";

interface Action {
	type: string;
	payload: {
		id: any;
		dimension: string;
		price?: number;
		shipping_price?: number;
		color?: any;
	};
}

interface Item {
	id: any;
	dimension: string;
	price: number;
	quantity: number;
	shipping_price: number;
	color: any;
}

const cart = (state: Item[] = [], action: Action): Item[] => {
	switch (action.type) {
		case variantConstants.ADDED_TO_CART:
			const itemToAdd = state.find(
				(item) =>
					item.id === action.payload.id &&
					item.dimension === action.payload.dimension
			);
			if (itemToAdd) {
				return state.map((item) =>
					item.id === action.payload.id &&
					item.dimension === action.payload.dimension
						? { ...item, quantity: item.quantity + 1 }
						: item
				);
			} else {
				return [
					...state,
					{
						id: action.payload.id,
						dimension: action.payload.dimension,
						price: action.payload.price!,
						shipping_price: action.payload.shipping_price!,
						color: action.payload.color,
						quantity: 1,
					},
				];
			}

		case variantConstants.REMOVED_FROM_CART:
			return state
				.map((item) => {
					if (
						item.id === action.payload.id &&
						item.dimension === action.payload.dimension
					) {
						return item.quantity > 1
							? { ...item, quantity: item.quantity - 1 }
							: item;
					}
					return item;
				})
				.filter((item) => item.quantity > 0);

		case variantConstants.ALL_REMOVED_FROM_CART:
			return state.filter(
				(item) =>
					!(
						item.id === action.payload.id &&
						item.dimension === action.payload.dimension
					)
			);

		case variantConstants.CLEAR_ALL_REMOVED_FROM_CART:
			return [];

		default:
			return state;
	}
};

export default cart;
