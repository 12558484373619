const _googleAnalyticsTrackingId: string =
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "";

const _apiHostUrl: string =
	process.env.REACT_APP_API_HOST_URL ?? "http://127.0.0.1:2424";

const _apiStrapiHost: string =
	process.env.REACT_APP_STRAPI_HOST_URL ?? "http://localhost:1337";

const _apiStrapiUrl: string =
	process.env.REACT_APP_STRAPI_API_URL ?? "http://localhost:1337/api";

const _apiTunnelUrl: string =
	process.env.REACT_APP_TUNNEL_URL ??
	"http://localhost:2424/api/v1/tunnel/printful/";

const aicommerce_prefix = "/api/v1";
const tunnel = "/tunnel";

export const serviceConfig = {
	googleAnalyticsTrackingId: _googleAnalyticsTrackingId,
	apiHost: _apiHostUrl,
	strapiApi: _apiStrapiUrl,
	strapiHost: _apiStrapiHost,
	apiPrefix: aicommerce_prefix,
	apiTunnel: tunnel,
	apiTunnelUrl: _apiTunnelUrl,
	shipping_count_percentage:
		process.env.REACT_APP_SHIPPING_COUNT_PERCENTAGE ?? 0.2,
	show_logs: process.env.REACT_APP_SHOW_LOGS
		? parseInt(process.env.REACT_APP_SHOW_LOGS)
		: 0,
	useMockApi: process.env.REACT_APP_USE_MOCK_API
		? parseInt(process.env.REACT_APP_USE_MOCK_API)
		: 1,
	keys: {
		printful_token_key:
			process.env.REACT_APP_PRINTFUL_TOKEN_KEY ??
			"tGdV1U7NITbry5rxK0NDu1wcPAyCpzFCjFr9CZWZ",
	},
	routes: {
		generate_image_route:
			process.env.REACT_APP_GENERATE_IMAGE_ROUTE ?? "/api/v1/generate_image/",
		random_generate_image_route:
			process.env.REACT_APP_RANDOM_GENERATE_IMAGE_ROUTE ??
			"/api/v1/random_generate_image/",
		enchance_resize_route: "/api/v1/resize-image/",
		remake: "/api/v1/remake",
		get_tax_price: process.env.REACT_APP_GET_TAX_PRICE ?? "/api/v1/tax/",

		create_generate_product_route:
			process.env.REACT_APP_CREATE_GENERATE_PRODUCT_ROUTE ??
			"/api/v1/printful/store/create-generate-products",

		check_generate_product_route:
			process.env.REACT_APP_CHECK_GENERATE_PRODUCT_ROUTE ??
			"/api/v1/printful/store/check-generate-products",
		get_store_by_id: process.env.REACT_APP_GET_STORE_BY_ID ?? "/api/v1/store",

		product_info:
			process.env.REACT_APP_PRINTFUL_PRODUCT_ROUTE ?? "/store/products",
		login: process.env.REACT_APP_API_LOGIN_ROUTE ?? "/api/v1/login/",
		auth_update:
			process.env.REACT_APP_API_AUTH_UPDATE_ROUTE ?? "/api/v1/auth-update/",
		register:
			process.env.REACT_APP_API_REGISTER_ROUTE ?? "/api/v1/auth-register/",
		reset: process.env.REACT_APP_API_REGISTER_ROUTE ?? "/api/v1/auth-reset/",
		forget: process.env.REACT_APP_API_REGISTER_ROUTE ?? "/api/v1/auth-forget/",

		payment_cards:
			process.env.REACT_APP_API_PAYMENT_CARDS_ROUTE ?? "/api/v1/payment-cards/",
		wishlist: process.env.REACT_APP_API_WISHLIST_ROUTE ?? "/api/v1/wishlist/",
		category: process.env.REACT_APP_API_CATEGORY_ROUTE ?? "/api/v1/categories/",
		category_variants:
			process.env.REACT_APP_API_CATEGORY_VARIANTS_ROUTE ??
			"/api/v1/categories/",
		subscribe:
			process.env.REACT_APP_API_SUBSCRIBE_ROUTE ?? "/api/v1/subscribe/",
		contact: process.env.REACT_APP_API_SUBSCRIBE_ROUTE ?? "/api/v1/contact/",
		addresses:
			process.env.REACT_APP_API_ADDRESSES_ROUTE ?? "/api/v1/addresses/",
		designs: process.env.REACT_APP_API_ADDRESSES_ROUTE ?? "/api/v1/design/",
		countries:
			process.env.REACT_APP_API_COUNTRIES_ROUTE ?? "/api/v1/countries/",
		google_auth:
			process.env.REACT_APP_API_GOOGLE_AUTH ?? "/api/v1/google-auth/",
		auth_request:
			process.env.REACT_APP_API_AUTH_REQUEST_ROUTE ?? "/api/v1/auth-request/",
		auth_verify:
			process.env.REACT_APP_API_AUTH_VERIFY_ROUTE ?? "/api/v1/auth-verify/",
		sync_orders:
			process.env.REACT_APP_API_SYNC_ORDERS_ROUTE ?? "/synch-orders/",
		create_online_payment:
			process.env.REACT_APP_API_CREATE_ONLINE_PAYMENT ??
			"/create-checkout-session/",
		validate_online_payment:
			process.env.REACT_APP_API_VALIDATE_ONLINE_PAYMENT ?? "/validate-payment/",
		cancel_online_payment:
			process.env.REACT_APP_API_CANCEL_ONLINE_PAYMENT ?? "/cancel-payment/",
		create_payment_intent:
			process.env.REACT_APP_API_CREATE_PAYMENT_INTENT ??
			"/api/v1/create-payment-intent",
	},
	urls: {
		printful_url:
			process.env.REACT_APP_PRINTFUL_URL ?? "https://api.printful.com",
	},
};

export const namesConfig = {
	company_name: process.env.REACT_APP_COMPANY_NAME ?? "AI.Based",
	company_email: process.env.REACT_APP_COMPANY_EMAIL ?? "aiprintable@gmail.com",
	company_webAddress:
		process.env.REACT_APP_COMPANY_WEBADDRESS ?? "www.aiprintable.com",
	company_phone: process.env.REACT_APP_COMPANY_PHONE ?? "+9936500000",
};
