import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./CategoryFilterPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";

import { Button, ProductCardItem } from "common";
import CategoryCollection from "components/CategoryCollection/CategoryCollection";
import { catalogService } from "airedux/services";
import { fetchVariantById } from "airedux/actions";
import { getFilterVariants } from "airedux/selectors";
import ReactGA from "react-ga";

const CategoryFilterPage: React.FC = ({
	app_state,
	fetchVariantById,
}: any): JSX.Element => {
	const [variant_ids, set_variant_ids] = useState([]);
	const [variants_data_list, set_variants_data_list]: any = useState([]);
	const [fetch_done, set_fetch_done]: any = useState(false);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const fetch_category_variants = async (id: any) => {
		ReactGA.event({
			category: "CategoryVariants",
			action: "CategoryVariants",
			label: "Category Variants",
			value: 0,
		});
		const response = await catalogService.fetchCategoryVariants(id);
		response.data &&
			response.data.variants &&
			set_variant_ids(response.data.variants);
		set_fetch_done(false);
	};
	const params = useParams();
	// useEffect(() => {
	// 	params.id && fetch_category_variants(parseInt(params.id));
	// }, [params.id]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (variant_ids.length > 0) {
			const data = getFilterVariants(app_state, variant_ids);
			set_variants_data_list(data);
		}
	}, [app_state.variant, variant_ids]);

	useEffect(() => {
		!fetch_done &&
			variant_ids.length > 0 &&
			variant_ids.map((id: any) => {
				fetchVariantById(id);
				set_fetch_done(true);
			});
	}, [variant_ids, fetch_done]);

	return (
		<div className={cn(styles.categoryfilterpage)}>
			<SubNavbar
				disabled="Home / "
				active="Product categories"
				pageName="Product categories"
			/>
			<div className={cn(styles.categorycontent)}>
				<CategoryCollection
					mainpage={false}
					label="Other categories"
					listClassName={true}
				/>
				<div className={cn(styles.row)}>
					<div className={cn(styles.categoryproducts)}>
						{variants_data_list.length > 0 &&
							variants_data_list.map(
								(variant: any, idx: number) =>
									variant && (
										<ProductCardItem
											key={idx}
											name={variant.name}
											src={variant.files[1].preview_url}
											price={variant.retail_price}
											alt={variant.files[1].preview_url}
											imgSize="238"
											//discount="43%"
											//discountPrice="45$"
										/>
									)
							)}
					</div>
					{/* <Button appearance="ghost-white" className={cn(styles.button)}>
						View all products (87)
					</Button> */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	app_state: state,
});

const mapDispatchToProps = {
	fetchVariantById,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilterPage);
