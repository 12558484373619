import React from "react";
import { connect } from "react-redux";

import styles from "./Cart.module.css";
import cn from "classnames";
import { Htag } from "common/Htag/Htag";
import { DiscountBadge, P } from "common";

import {
	variantAddedToCart,
	variantRemovedFromCart,
	variantAllRemovedFromCart,
} from "airedux/actions";
import { getVariantTotalCount, getVariantCartItems } from "airedux/selectors";
import { ErrorBoundary } from "modules/errors";
import AddToCartButton from "components/ProductDetails/AddToCartButton";
import { serviceConfig } from "configs";

interface ICartRowProps {
	checkoutPage?: boolean;
	orderPage?: boolean;
	invoicePage?: boolean;
	discount?: boolean;
	onIncrease?: any;
	onDecrease?: any;
	onDelete?: any;
	totalCount?: number | string;
	totalPrice?: number | string;
	cartData?: any;
	item?: any;
}

const CartRow: React.FC<ICartRowProps> = ({
	checkoutPage,
	orderPage,
	invoicePage,
	discount,
	onIncrease,
	onDecrease,
	totalCount,
	totalPrice,
	onDelete,
	cartData,
	item,
}): JSX.Element => {
	console.log("CART DATA", cartData, item);
	const selectedCartItem = cartData.find(
		(cartItem: any) => cartItem.id === item.product_id
	);

	const determineImageSrc = (item: any, selectedColor: string | undefined) => {
		// If there's a selected color, find its image in the product_colors
		if (selectedColor) {
			const colorDetail = item.product_colors.find(
				(colorObj: any) => colorObj.color.value === selectedColor
			);
			if (colorDetail && colorDetail.image) {
				return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${colorDetail.image}`;
			}
		}
		// If there's no selected color, or the color doesn't have an associated image, return the default image
		return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${item.product_image}`;
	};

	return (
		item && (
			<>
				{checkoutPage ? (
					<div className={cn(styles.cartcheckoutrowcontainer)}>
						<div className={cn(styles.cartrowimagediv)}>
							<img
								src={determineImageSrc(item, selectedCartItem?.color)}
								alt={determineImageSrc(item, selectedCartItem?.color)}
								className={cn(styles.cartrowimage)}
							/>
						</div>
						<div className={cn(styles.information)}>
							<P size="b1regular">{item.product_name}</P>
							<div className={cn(styles.checkoutsizediv)}>
								{(() => {
									const cartItem = cartData.find(
										(cartItem: any) => cartItem.id === item.product_id
									);
									return (
										<>
											{cartItem?.color && (
												<P size="b2regular">{cartItem.color}</P>
											)}
											{cartItem?.color && <span>&#8226;</span>}
											{cartItem?.dimension && (
												<P size="b2regular">{cartItem.dimension}</P>
											)}
										</>
									);
								})()}
							</div>
						</div>
						<div className={cn(styles.priceproductdiv)}>
							<AddToCartButton
								variantId={item.product_id}
								withCounter={true}
								selectedDimension={
									selectedCartItem ? selectedCartItem.dimension : null
								}
								selectedDimensionPrice={
									selectedCartItem ? selectedCartItem.price : null
								}
							/>
							{item.discount && (
								<DiscountBadge
									appearance="squared"
									className={cn(styles.itemone)}
								>
									{item.discount}
								</DiscountBadge>
							)}
							{item.discount ? (
								<div className={cn(item.discount ? styles.itemtwo : "")}>
									<>
										<P size="b1line" className={cn(styles.discount)}>
											$
											{(() => {
												const cartItem = cartData.find(
													(cartItem: any) => cartItem.id === item.product_id
												);
												return cartItem?.price;
											})()}
										</P>
										<Htag tag="s2-bold">
											$
											{(() => {
												const cartItem = cartData.find(
													(cartItem: any) => cartItem.id === item.product_id
												);
												return cartItem?.price;
											})()}
										</Htag>
									</>
								</div>
							) : (
								<Htag tag="s2-bold">
									$
									{(() => {
										const cartItem = cartData.find(
											(cartItem: any) => cartItem.id === item.product_id
										);
										return cartItem?.price;
									})()}
								</Htag>
							)}
						</div>
					</div>
				) : orderPage ? (
					<div className={cn(styles.cartrowordercontainer)}>
						<div className={cn(styles.cartrowimagediv)}>
							<img
								src={determineImageSrc(item, selectedCartItem?.color)}
								alt={determineImageSrc(item, selectedCartItem?.color)}
								className={cn(styles.cartrowimage)}
							/>
						</div>
						<div className={cn(styles.orderinformation)}>
							<P size="b1regular">{item.product_name}</P>
							<div className={cn(styles.checkoutsizediv)}>
								{(() => {
									const cartItem = cartData.find(
										(cartItem: any) => cartItem.id === item.product_id
									);
									return (
										<>
											{cartItem?.color && (
												<P size="b2regular">{cartItem.color}</P>
											)}
											{cartItem?.color && <span>&#8226;</span>}
											{cartItem?.dimension && (
												<P size="b2regular">{cartItem.dimension}</P>
											)}
										</>
									);
								})()}
							</div>
						</div>
						<div className={cn(styles.orderpricewithcounter)}>
							<AddToCartButton
								variantId={item.product_id}
								withCounter={true}
								selectedDimension={
									selectedCartItem ? selectedCartItem.dimension : null
								}
								selectedDimensionPrice={
									selectedCartItem ? selectedCartItem.price : null
								}
							/>
							<div className={cn(styles.orderpriceproductdiv)}>
								{item.discount && (
									<DiscountBadge
										appearance="squared"
										className={cn(styles.itemone)}
									>
										-30%
									</DiscountBadge>
								)}
								{item.discount ? (
									<div className={cn(item.discount ? styles.itemtwo : "")}>
										<>
											<P size="b1line" className={cn(styles.discount)}>
												$
												{(() => {
													const cartItem = cartData.find(
														(cartItem: any) => cartItem.id === item.product_id
													);
													return cartItem?.price;
												})()}
											</P>
											<Htag tag="s2-bold">
												$
												{(() => {
													const cartItem = cartData.find(
														(cartItem: any) => cartItem.id === item.product_id
													);
													return cartItem?.price;
												})()}
											</Htag>
										</>
									</div>
								) : (
									<Htag tag="s2-bold">
										$
										{(() => {
											const cartItem = cartData.find(
												(cartItem: any) => cartItem.id === item.product_id
											);
											return cartItem?.price;
										})()}
									</Htag>
								)}
							</div>
						</div>
					</div>
				) : invoicePage ? (
					<div className={cn(styles.cartrowinvoicecontainer)}>
						<div className={cn(styles.cartrowimagediv)}>
							<img
								src={determineImageSrc(item, selectedCartItem?.color)}
								alt={determineImageSrc(item, selectedCartItem?.color)}
								className={cn(styles.cartrowimage)}
							/>
						</div>
						<div className={cn(styles.information)}>
							<P size="b1regular">{item.product_name}</P>
							<div className={cn(styles.sizediv)}>
								<P size="b2regular">
									{item.product_colors && item.product_colors}
								</P>
								<span>&#8226;</span>
								<P size="b2regular">
									{item.product_sizes && item.product_sizes}
								</P>
								<span>&#8226;</span>
								<P size="b2regular">QTY</P>
								<P size="b2regular">{totalCount}</P>
							</div>
							<div className={cn(styles.priceinvoiceproductdiv)}>
								{item.discount && (
									<DiscountBadge
										appearance="squared"
										className={cn(styles.itemone)}
									>
										-30%
									</DiscountBadge>
								)}
								{item.discount ? (
									<div className={cn(item.discount ? styles.itemtwo : "")}>
										<>
											<P size="b1line" className={cn(styles.discount)}>
												$50.99
											</P>
											<Htag tag="s2-bold">${item.product_price}</Htag>
										</>
									</div>
								) : (
									<Htag tag="s2-bold">${item.product_price}</Htag>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className={cn(styles.cartrowcontainer)}>
						<div className={cn(styles.cartrowimagediv)}>
							<img
								src={determineImageSrc(item, selectedCartItem?.color)}
								alt={determineImageSrc(item, selectedCartItem?.color)}
								className={cn(styles.cartrowimage)}
							/>
						</div>
						<div className={cn(styles.information)}>
							<P size="b1regular">{item.product_name}</P>
							<div className={cn(styles.sizediv)}>
								{(() => {
									const cartItem = cartData.find(
										(cartItem: any) => cartItem.id === item.product_id
									);
									return (
										<>
											{cartItem?.dimension && (
												<P size="b2regular">{cartItem.dimension}</P>
											)}
											{cartItem?.dimension && <span>&#8226;</span>}
											{cartItem?.color && (
												<P size="b2regular">{cartItem.color}</P>
											)}
											{cartItem?.color && <span>&#8226;</span>}
											{cartItem?.quantity && <P size="b2regular">QTY</P>}
											{cartItem?.quantity && (
												<P size="b2regular">{cartItem.quantity}</P>
											)}
										</>
									);
								})()}
							</div>
							<Htag tag="s2-medium">
								$
								{(() => {
									const cartItem = cartData.find(
										(cartItem: any) => cartItem.id === item.product_id
									);
									return cartItem?.price;
								})()}
							</Htag>
						</div>
					</div>
				)}
			</>
		)
	);
};

const mapStateToProps = (state: any, { item }: any) => {
	const totalData = getVariantTotalCount(state, item);
	return {
		items: getVariantCartItems(state),
		totalCount: totalData.totalCount,
		totalPrice: totalData.totalPrice,
		cartData: state.cart,
	};
};

const mapDispatchToProps = {
	onIncrease: variantAddedToCart,
	onDecrease: variantRemovedFromCart,
	onDelete: variantAllRemovedFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartRow);
