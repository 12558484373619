import React from "react";

import { ProductCardItemProps } from "./ProductCardItem.props";
import styles from "./ProductCardItem.module.css";
import cn from "classnames";

import { P } from "common/P/P";
import { DiscountBadge } from "common/DiscountBadge/DiscountBagde";
import WishlistButton from "components/WishlistButton/WishlistButton";

export const ProductCardItem = ({
	className,
	name,
	src,
	alt,
	price,
	discount,
	discountPrice,
	imgSize,
	id,
	productColors,
	...props
}: ProductCardItemProps): JSX.Element => {
	console.log("IMAGE", src, productColors);
	return (
		<div className={cn(styles.productcarditem, className)} {...props}>
			<div
				className={cn(styles.imagediv, {
					[styles.minsize]: imgSize === "218",
					[styles.maxsize]: imgSize === "238",
				})}
			>
				<img src={src} className={cn(styles.image)} alt={alt} loading="lazy" />
				<WishlistButton data_type="variant" id={id} />
				{discount && (
					<DiscountBadge appearance="card" className={cn(styles.badge)}>
						-30%
					</DiscountBadge>
				)}
			</div>
			<div className={cn(styles.container)}>
				<P size="b1regular">{name}</P>
				<div className={cn(discount ? styles.pricediv : "")}>
					{discount ? (
						<div className={cn(styles.withdiscount)}>
							<P size="b1line" className={cn(styles.discprice)}>
								{discountPrice}
							</P>
							<P size="b1medium" className={cn(styles.realprice)}>
								{price}
							</P>
						</div>
					) : (
						<P size="b1medium" className={cn(styles.realprice)}>
							{price}
						</P>
					)}
				</div>
			</div>
		</div>
	);
};
