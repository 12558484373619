/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { authService } from "airedux/services";

import styles from "./Auth.module.css";
import cn from "classnames";

import { Modal } from "common/Modal/Modal";
import { Button, Input, P } from "common";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toastMessage } from "airedux/helpers";
import { routeConstants } from "routes";
import { useNavigate } from "react-router-dom";

interface IPropsForget {
	onStageChange?: any;
	handleValidationData: any;
}

const AuthForget: React.FC<IPropsForget> = ({
	onStageChange,
	handleValidationData,
}) => {
	const [credentials, set_credentials] = useState("");
	const [loading, set_loading] = useState(false);
	const [validationData, set_validationData] = useState({
		credentials: credentials,
		validator_phone_number: "",
		responseMessage: "",
		registerToken: "",
	});
	useEffect(() => {
		set_validationData({
			...validationData,
			credentials: credentials,
		});
	}, [credentials]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		set_loading(true);
		authService
			.authForget(credentials) // Use the updated authService method for forgot password
			.then(
				(response: any) => {
					// Handle success
					set_loading(false);
					toastMessage({
						type: "success",
						message: response.message,
						position: "center-top",
					});
					const newValidationData = {
						...validationData,
						responseMessage: response.message,
						// The token will be set here if it's returned by the backend in the response
						registerToken: response.data?.registerToken || "",
					};
					set_validationData(newValidationData);
					handleValidationData(newValidationData);
					if (onStageChange) onStageChange(2);
				},
				(error: any) => {
					// Handle error
					set_loading(false);
					toastMessage({
						type: "error",
						message: error.message || "An unexpected error occurred",
						position: "center-top",
					});
				}
			);
	};

	const handleSuccess = (response: any) => {
		toastMessage({
			type: "success",
			message: response.message,
			position: "center-top",
		});
		const newValidationData = {
			...validationData,
			responseMessage: response.message,
		};
		set_validationData(newValidationData);
		handleValidationData(newValidationData);
		onStageChange(2);
	};

	const navigate = useNavigate();
	return (
		<>
			<Modal
				title="Forget password"
				openModal={true}
				setOpenModal={(e: any) =>
					e === false && navigate(`${routeConstants.root.route}`)
				}
				modalClass={true}
				button={
					!loading ? (
						<Button
							appearance="black"
							className={cn(styles.buttoncomponent)}
							onClick={handleSubmit}
						>
							Send
						</Button>
					) : (
						<Spinner />
					)
				}
			>
				<form onSubmit={!loading ? handleSubmit : () => ({})}>
					<div className={cn(styles.logincontent)}>
						<P size="b1regular">
							We will send verification to reset your password
						</P>
						<div className={cn(styles.inputs)}>
							<Input
								label="Email address"
								appearance="default"
								type="email"
								name="email"
								placeholder="hello@artifical.com"
								onChange={(e) => set_credentials(e.target.value)}
								required
							/>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
	loading: state.auth.loading,
});
export default connect(mapStateToProps)(AuthForget);
