import { combineReducers } from "redux";

import { generateImage as generate } from "./generateImage.reducer";
import { resource } from "./resource.reducer";
import { resourcesPage } from "./resourcesPage.reducer";
import { resourcePage } from "./resourcePage.reducer";
import { category } from "./category.reducer";
import { auth } from "./auth.reducer";
import { alert } from "./alert.reducer";
import cart from "./cart.reducer";
import { variantPage } from "./variantPage.reducer";
import { categoryPage } from "./categoryPage.reducer";
import { address } from "./address.reducer";
import { tax } from "./tax.reducer";
import { shipping } from "./shipping.reducer";
import { paymentCard } from "./paymentCard.reducer";
import { wishlist } from "./wishlist.reducer";
import { variant } from "./variant.reducer";
import { design } from "./design.reducer";

export * from "./generateImage.reducer";

export const rootReducer = () =>
	combineReducers({
		wishlist,
		address,
		tax,
		shipping,
		paymentCard,
		variant,
		variantPage,
		generate,
		resource,
		resourcePage,
		resourcesPage,
		categoryPage,
		category,
		auth,
		alert,
		cart,
		design,
	});

export type RootState = ReturnType<typeof rootReducer>;
