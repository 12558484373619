import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./ProductPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { GeneratedProductItem } from "common";
import CategoryCollection from "components/CategoryCollection/CategoryCollection";
import ProductCardCollection from "components/ProductCardCollection/ProductCardCollection";

import { fetchResourceById } from "airedux/actions";
import { getResourceById } from "airedux/selectors";
import { resourcePage } from "airedux/reducers/resourcePage.reducer";

import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import { serviceConfig } from "configs";

interface IPropsProductPage {
	res_id: any;
	storeType: "client" | "admin";
}

const ProductPage: React.FC<IPropsProductPage> = (props: any) => {
	const [loading, setLoading] = useState(true);
	const { fetchResourceById, resource, res_id, storeType } = props;

	console.log("Resource in product page", resource);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setLoading(true);
		try {
			fetchResourceById(res_id, storeType)
				.then(() => setLoading(false))
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
				});
		} catch (err: any) {
			console.log(err);
			setLoading(false);
		}
	}, [res_id, fetchResourceById, storeType]);

	if (loading) {
		return <Spinner />;
	}

	return (
		resource && (
			<div className={cn(styles.productpage)}>
				<SubNavbar
					disabled="Home / Generate /"
					active="Generated Image"
					pageName="Generated Image"
				/>
				<div className={cn(styles.productdiv)}>
					<GeneratedProductItem
						productPage={true}
						productImage={`${resource.thumbnail}`}
						desc={resource.name}
						id={resource.id}
					/>
					<div className={cn(styles.container)}>
						{/* <CategoryCollection
							mainpage={false}
							label="Products with this design"
						/> */}
						<ProductCardCollection resource={resource} storeType={storeType} />
					</div>
				</div>
			</div>
		)
	);
};

const mapStateToProps = (state: any) => ({
	resource: getResourceById(state.resource.data, state.resourcePage.id),
});

const mapDispatchToProps = {
	fetchResourceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
