import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import { authBearerHeaderAsync } from "airedux/helpers";

const fetchAll = async () => {
	const requestOptions = {
		headers: await authBearerHeaderAsync(),
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.wishlist}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => response.data);
};

const post_wishlist = async (payload: any, action = "add") => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			...(await authBearerHeaderAsync()),
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.wishlist}?action=${action}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => ({
			status: response.status,
			...response,
		}));
};

export const wishlistService = {
	fetchAll,
	post_wishlist,
};
