import React, { useEffect, useState } from "react";

import styles from "./ShopPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import ReactGA from "react-ga";
import ProductCardCollection from "components/ProductCardCollection/ProductCardCollection";
import { serviceConfig } from "configs";
import { ProductCardItem } from "common/ProductCardItem/ProductCardItem";
import { Link } from "react-router-dom";
import { routeConstants } from "routes";

const ShopPage: React.FC = (): JSX.Element => {
	const [shopStores, setShopStores] = useState<any>([]);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		const fetchShopStores = async () => {
			try {
				const response = await fetch(
					`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/all-shop-stores`
				); // Replace '/api/shop-stores' with your actual API endpoint
				const result = await response.json();
				if (result.success) {
					// const replicatedData = Array(5).fill(result.data).flat();
					// setShopStores(replicatedData);
					setShopStores(result.data);
				} else {
					console.error("Failed to fetch shop stores:", result.message);
				}
			} catch (error) {
				console.error("Error fetching shop stores:", error);
			}
		};

		fetchShopStores();
		window.scrollTo(0, 0);
	}, []);

	const determineImageSrc = (variant: any) => {
		if (variant.product_colors && variant.product_colors.length > 0) {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_colors[0].image}`;
		} else {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_image}`;
		}
	};

	return (
		<div className={cn(styles.shoppage)}>
			<SubNavbar disabled="Home / " active="Shop" pageName="Shops" />
			<div className={cn(styles.container)}>
				<div className={cn(styles.productcardcollection)}>
					{shopStores.map((store: any, idx: number) => {
						const sortedDimensions = store.product.product_dimensions.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						const sortedSizes = store.product.product_sizes.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						let lowestPrice = store.product.product_price;
						if (sortedDimensions.length > 0) {
							lowestPrice = sortedDimensions[0].price;
						} else if (sortedSizes.length > 0) {
							lowestPrice = sortedSizes[0].price;
						}
						return (
							<Link
								key={idx}
								to={`${routeConstants.shop_product_details.route}/${store.id}?product_id=${store.product.product_id}`} // Update the URL as needed
							>
								<ProductCardItem
									name={store.product.product_name}
									src={determineImageSrc(store.product)}
									price={`${lowestPrice} ${store.product.product_currency}`}
									alt={store.product.product_name}
									imgSize="218"
									id={store.id}
									productColors={store.product.product_colors}
								/>
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ShopPage;
