import { resourceConstants, variantConstants } from "airedux/constants";
import { wishlistService as service } from "airedux/services";

export const fetchWishlist = () => async (dispatch: any) => {
	dispatch({
		type: variantConstants.WISHLIST_FETCH_START,
	});
	try {
		const data = await service.fetchAll();
		dispatch({
			type: variantConstants.WISHLIST_FETCH_SUCCESS,
			payload: data,
		});
	} catch (err) {
		dispatch({
			type: variantConstants.WISHLIST_FETCH_FAILURE,
			payload: err,
			error: true,
		});
	}
};

export const addToWishlist = (id: any, data_type = "variant") => {
	return {
		type:
			data_type === "variant"
				? variantConstants.ADDED_TO_WISHLIST
				: resourceConstants.ADDED_TO_WISHLIST,
		payload: id,
	};
};

export const removeFromWishlist = (id: any, data_type = "variant") => {
	return {
		type:
			data_type === "variant"
				? variantConstants.REMOVED_FROM_WISHLIST
				: resourceConstants.REMOVED_FROM_WISHLIST,
		payload: id,
	};
};
