import { serviceConfig } from "configs";
import { authBearerHeaderAsync, handleResponse } from "airedux/helpers";
import { fetchWithCred } from "airedux/helpers";

const logout = () => {
	localStorage.removeItem("user");
	document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

const requestAuth = async (payload: string, verify = false) => {
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.auth_request}?verify=${
			verify ? 1 : 0
		}`,
		{ headers: { Email: payload } }
	).then(handleResponse);
};

const verifyAuth = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: { "Content-Type": "application/json; charset=UTF-8" },
	};
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.auth_verify}`,
		requestOptions
	).then(handleResponse);
};

const googleAuth = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.google_auth}?type=rp_acc`,
		requestOptions
	).then(handleResponse);
};

const login_request = (username = "", password = "") => {
	const auth_header =
		username.length > 0 && password.length > 0
			? btoa(`${username}:${password}`)
			: "";
	if (!auth_header) {
		return Promise.reject("Fill the required fields!");
	}

	const requestOptions: any = {
		headers: {
			Authorization: `Basic ${auth_header}`,
		},
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.login}`,
		requestOptions
	).then(handleResponse);
};

const login = async (username: string, password: string) => {
	return await login_request(username, password);
};

const editProfile = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			...(await authBearerHeaderAsync()),
		},
	};
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.auth_update}`,
		requestOptions
	).then(handleResponse);
};

const register_rp_acc = async (registerToken: string, payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Token: registerToken,
		},
	};
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.register}`,
		requestOptions
	).then(handleResponse);
};

const reset_password = async (registerToken: string, payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Token: registerToken,
		},
	};
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.reset}`,
		requestOptions
	).then(handleResponse);
};

const authForget = async (email: string) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify({ email }),
		headers: { "Content-Type": "application/json; charset=UTF-8" },
	};
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.forget}`,
		requestOptions
	).then(handleResponse);
};

export const authService = {
	login,
	logout,
	requestAuth,
	verifyAuth,
	googleAuth,
	editProfile,
	register_rp_acc,
	reset_password,
	authForget,
};
