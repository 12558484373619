import React, { useEffect } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { MutatingDots, RotatingLines } from "react-loader-spinner";

import styles from "./Spinner.module.css";
import cn from "classnames";

const Spinner: React.FC = () => {
	const { promiseInProgress } = usePromiseTracker();
	// useEffect(() => {
	// 	const body: any = document.querySelector("body");
	// 	body.style.overflow = "hidden";

	// 	setTimeout(() => {
	// 		body.style.overflow = null;
	// 	}, 2000);
	// }, []);
	return (
		<>
			{/* {promiseInProgress && ( */}
			<div className={cn(styles.spinner)}>
				<MutatingDots
					color="#E31319"
					secondaryColor="#111111"
					height="100"
					width="100"
				/>
				{/* <RotatingLines
					strokeColor="black"
					strokeWidth="5"
					animationDuration="0.75"
					width="100"
				/> */}
			</div>
			{/* )} */}
		</>
	);
};

export default Spinner;
