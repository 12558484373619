import { isWishlisted } from "airedux/selectors";
import { ErrorBoundary } from "modules/errors";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";

import { connect } from "react-redux";

import cn from "classnames";
import {
	addToWishlist,
	removeFromWishlist,
} from "airedux/actions/wishlist.actions";

import styles from "common/ProductImageItem/ProductImageItem.module.css";
import { ReactComponent as WishlistIcon } from "common/Icons/heart.svg";
import { ReactComponent as WishlistFilledIcon } from "common/Icons/heart-filled.svg";
import { wishlistService } from "airedux/services";

const WishlistButton = ({
	id,
	data_type,
	isLargeIcon,
	isWishlisted,
	component_type,
	onAdd,
	onRemove,
	loggedIn,
	button_styles,
}: any) => {
	const handleAdd = (event: any) => {
		event.preventDefault();
		if (loggedIn) {
			wishlistService.post_wishlist(
				{
					[data_type === "variant" ? "variant_id" : "product_id"]: id,
				},
				"add"
			);
		}
		onAdd(id, data_type);
	};

	const handleRemove = (event: any) => {
		event.preventDefault();
		if (loggedIn) {
			wishlistService.post_wishlist(
				{
					[data_type === "variant" ? "variant_id" : "product_id"]: id,
				},
				"delete"
			);
		}
		onRemove(id, data_type);
	};

	return isLargeIcon ? (
		<Button
			appearance="ghost-white"
			icon={isWishlisted ? <WishlistFilledIcon /> : <WishlistIcon />}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			<P size="b1regular">
				{!isWishlisted ? "Add to favorites" : "Remove from favorites"}
			</P>
		</Button>
	) : component_type === "button" ? (
		<Button
			appearance="ghost-white"
			className={button_styles ?? cn(styles.icondiv)}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			{isWishlisted ? (
				<WishlistFilledIcon />
			) : (
				<WishlistIcon className={button_styles ?? cn(styles.icon)} />
			)}
		</Button>
	) : (
		<span
			className={button_styles ?? cn(styles.icondiv)}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			{isWishlisted ? (
				<WishlistFilledIcon />
			) : (
				<WishlistIcon className={cn(styles.icon)} />
			)}
		</span>
	);
};

const mapStateToProps = (state: any, props: any) => {
	const { id, data_type } = props;
	return {
		isWishlisted: isWishlisted(state, id, data_type),
		loggedIn: state.auth.loggedIn,
	};
};

const mapDispatchToProps = {
	onAdd: addToWishlist,
	onRemove: removeFromWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistButton);
