import { getVariantTotalCount } from "airedux/selectors";
import { ErrorBoundary } from "modules/errors";

import { connect } from "react-redux";

import {
	variantAddedToCart,
	variantRemovedFromCart,
	variantAllRemovedFromCart,
} from "airedux/actions";

import styles from "./ProductDetails.module.css";
import cn from "classnames";
import { Button } from "common";
import { CounterButton } from "common/CounterButton/CounterButton";

const AddToCartButton = ({
	variantId,
	onIncrease,
	onDecrease,
	totalCount,
	onDelete,
	withCounter,
	selectedDimension,
	selectedDimensionPrice,
	selectedDimensionShippingPrice,
	selectedColor,
	onClick,
}: any) => {
	console.log("VARIANT ID", variantId);
	const handleIncrease = () =>
		onIncrease(
			variantId,
			selectedDimension,
			selectedDimensionPrice,
			selectedDimensionShippingPrice,
			selectedColor
		);
	const handleDelete = () => onDelete(variantId, selectedDimension);
	return withCounter ? (
		<CounterButton
			onIncrease={() =>
				onIncrease(
					variantId,
					selectedDimension,
					selectedDimensionPrice,
					selectedDimensionShippingPrice,
					selectedColor
				)
			}
			onDecrease={() => onDecrease(variantId, selectedDimension)}
			counter={totalCount}
		/>
	) : (
		<Button
			appearance="black"
			className={cn(styles.add)}
			onClick={onClick ? onClick : totalCount ? handleDelete : handleIncrease}
		>
			{totalCount ? "Remove" : "Add to cart"}
		</Button>
	);
};

const mapStateToProps = (state: any, props: any) => {
	const { variantId } = props;
	const totalData = getVariantTotalCount(state, variantId);
	return {
		totalCount: totalData.totalCount,
		totalPrice: totalData.totalPrice,
	};
};

const mapDispatchToProps = {
	onIncrease: variantAddedToCart,
	onDecrease: variantRemovedFromCart,
	onDelete: variantAllRemovedFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartButton);
