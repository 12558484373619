import React, { useEffect } from "react";

import styles from "./HelpCentrePage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import {
	CollapseInformationItem,
	CollapseInformationWrapper,
	Htag,
	P,
} from "common";

import ReactGA from "react-ga";

const HelpCentrePage: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className={cn(styles.helpcentrepage)}>
			<SubNavbar
				disabled="Home /"
				active="Help centre"
				pageName="Help centre"
			/>
			<div className={cn(styles.container)}>
				<Htag tag="h2" className={styles.textstyle}>
					Help centre
				</Htag>
				<P size="p1" className={styles.textstyle}>
					Welcome to our Help Center! We're here to help answer any questions
					you may have about our product and services. Below are answers to some
					of the most commonly asked questions:
				</P>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						openCollapse={true}
						title={<Htag tag="s2-medium">How do I create an account?</Htag>}
					>
						<P size="p1">
							To create an account, click the "Sign Up" button at the top of our
							website and follow the prompts.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={<Htag tag="s2-medium">How do I generate an AI image?</Htag>}
					>
						<P size="p1">
							Let your imagination run wild! Type your idea into the search bar
							and click "Generate" to see your vision come to life.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={
							<Htag tag="s2-medium">
								How do I visualize my AI image on a product?
							</Htag>
						}
					>
						<P size="p1">
							After generating your AI image, you can see how it looks on
							various products. Your image will automatically appear on a range
							of products, allowing you to see how your design looks on each
							one.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={<Htag tag="s2-medium">How do I place an order?</Htag>}
					>
						<P size="p1">
							Select the product you want to purchase, add it to your cart, and
							follow the checkout process to place your order.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={
							<Htag tag="s2-medium">
								How long will it take to receive my order?
							</Htag>
						}
					>
						<P size="p1">
							Delivery times vary depending on your location and the product you
							ordered. You can find estimated delivery times on our website or
							contact us for more information.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={
							<Htag tag="s2-medium">
								What if I'm not satisfied with my order?
							</Htag>
						}
					>
						<P size="p1">
							We want you to be completely satisfied with your purchase. If
							you're not happy with your order, please contact us and we'll work
							with you to find a solution.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>

				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={<Htag tag="s2-medium">How do I track my order?</Htag>}
					>
						<P size="p1">
							You can track your order by logging into your account and clicking
							on "Order History" or by using the tracking number provided in
							your shipping confirmation email.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>

				<CollapseInformationWrapper>
					<CollapseInformationItem
						title={
							<Htag tag="s2-medium">How can I contact customer support?</Htag>
						}
					>
						<P size="p1">
							You can contact us through our website's contact form, by email,
							or by phone. Our customer support team is available to assist you
							with any questions or concerns you may have.
						</P>
					</CollapseInformationItem>
				</CollapseInformationWrapper>
			</div>
		</div>
	);
};

export default HelpCentrePage;
