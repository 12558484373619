import React from "react";

import styles from "./App.module.css";
import cn from "classnames";

import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router } from "react-router-dom";
import MainWrapper from "components/MainWrapper/MainWrapper";
import { PromptContext } from "components/GenerateProduct/PromptContext";

import ReactGA from "react-ga";
import { serviceConfig } from "configs";
import useBlockLogs from "hooks/useBlockLogs";

const TRACKING_ID = serviceConfig.googleAnalyticsTrackingId;
ReactGA.initialize(TRACKING_ID);

const App: React.FC = (): JSX.Element => {
	const [prompt, setPrompt] = React.useState("");

	const showLogs = serviceConfig.show_logs;

	useBlockLogs(showLogs);

	return (
		<PromptContext.Provider value={{ prompt, setPrompt }}>
			<Router>
				<div className={cn(styles.app)}>
					<MainWrapper />
				</div>
			</Router>
		</PromptContext.Provider>
	);
};

export default App;
