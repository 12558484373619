/* eslint-disable no-case-declarations */
import { variantConstants, resourceConstants } from "airedux/constants";
import * as R from "ramda";

function removeDups(arr: any) {
	return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
}

export const wishlist = (
	state = { variant: [], resource: [] },
	{ type, payload }: any
) => {
	switch (type) {
		case variantConstants.WISHLIST_FETCH_SUCCESS:
			const product_ids: any = [];
			const variant_ids: any = [];
			payload.map((item: any) => {
				item.product_id && product_ids.push(parseInt(item.product_id));
				item.variant_id && variant_ids.push(parseInt(item.variant_id));
			});
			//const newState = {
			//	loading: false,
			//	error: false,
			//	data: R.mergeLeft(
			//		state.data,
			//		R.indexBy(R.prop<string, string>("id"), payload)
			//	),
			//};
			return R.mergeRight(state, {
				variant: variant_ids,
				resource: product_ids,
			});

		case variantConstants.ADDED_TO_WISHLIST:
			return R.mergeRight(state, {
				variant: removeDups([...state.variant, payload]),
			});

		case variantConstants.REMOVED_FROM_WISHLIST:
			const newVariantWish = state.variant.filter((item: any) => {
				if (item === payload) {
					return false;
				}
				return true;
			});
			return R.mergeRight(state, {
				variant: newVariantWish,
			});

		case resourceConstants.ADDED_TO_WISHLIST:
			return R.mergeRight(state, {
				resource: removeDups([...state.resource, payload]),
			});

		case resourceConstants.REMOVED_FROM_WISHLIST:
			const newProductWish = state.resource.filter((item: any) => {
				if (item === payload) {
					return false;
				}
				return true;
			});
			return R.mergeRight(state, {
				resource: newProductWish,
			});

		default:
			return state;
	}
};

export default wishlist;
