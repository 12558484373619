import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import { authBearerHeaderAsync } from "airedux/helpers";

const fetch_addresses = async (userId: any) => {
	const requestOptions = {
		headers: { ...(await authBearerHeaderAsync()) },
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.addresses}?userId=${userId}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response) => response.data);
};

const addAddress = async (addressData: any) => {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			...(await authBearerHeaderAsync()),
		},
		body: JSON.stringify(addressData),
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.addresses}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response) => response.data);
};

const updateAddress = async (addressId: any, addressData: any) => {
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			...(await authBearerHeaderAsync()),
		},
		body: JSON.stringify(addressData),
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.addresses}${addressId}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response) => response);
};

const deleteAddress = async (addressId: any) => {
	const requestOptions = {
		method: "DELETE",
		headers: {
			...(await authBearerHeaderAsync()),
		},
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.addresses}${addressId}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response) => response);
};

export const addressService = {
	fetch_addresses,
	addAddress,
	updateAddress,
	deleteAddress,
};
