import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styles from "./ProductDetailsPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import ProductDetails from "components/ProductDetails/ProductDetails";
import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import { fetchResourceById } from "airedux/actions";
import { getResourceById } from "airedux/selectors";
import { connect } from "react-redux";
import { serviceConfig } from "configs";

interface IPropsProductDetailsPage {
	storeType: "client" | "shop";
}

const ProductDetailsPage: React.FC<IPropsProductDetailsPage> = (props: any) => {
	const { storeType, resource, fetchResourceById } = props;
	const [loading, setLoading] = useState(true);
	const { id } = useParams(); // Assuming 'id' is the parameter name in your route
	const search_params = new URLSearchParams(useLocation().search);
	const product_id = search_params.get("product_id");

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		if (id && product_id) {
			setLoading(true);

			// Fetch resource for other storeTypes
			fetchResourceById(id, storeType, product_id)
				.then(() => setLoading(false))
				.catch((err: any) => {
					console.error(err);
					setLoading(false);
				});
		}
	}, [id, product_id, fetchResourceById, storeType]);

	if (loading) {
		return <Spinner />;
	}
	const filtered_resource = resource?.products?.find(
		(product: any) => product.product_id === product_id
	);

	return (
		resource && (
			<div className={cn(styles.productdetailspage)}>
				<SubNavbar
					disabled="Home / Generate / Generate Image /  "
					active={filtered_resource?.product_name || ""}
					pageName="Product Details"
				/>
				<ProductDetails
					resource={storeType === "shop" ? resource.product : resource}
					isShop={storeType === "shop" && true}
				/>
			</div>
		)
	);
};

const mapStateToProps = (state: any) => ({
	resource: getResourceById(state.resource.data, state.resourcePage.id),
});

const mapDispatchToProps = {
	fetchResourceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
