import {
	resourceConstants as actionConstants,
	variantConstants,
} from "airedux/constants";
import { resourceService as service, variantService } from "airedux/services";

export const fetchResources = (storeType: string) => async (dispatch: any) => {
	dispatch({
		type: actionConstants.FETCH_START,
	});
	try {
		const data = await service.fetchAll("", storeType);
		dispatch({
			type: actionConstants.FETCH_SUCCESS,
			payload: data.result,
		});
	} catch (err) {
		dispatch({
			type: actionConstants.FETCH_FAILURE,
			payload: err,
			error: true,
		});
	}
};

export const fetchResourceById =
	(id: any, storeType: string, variant_id?: any) => async (dispatch: any) => {
		dispatch({ type: actionConstants.FETCH_BY_ID_START });
		try {
			let data;
			let variantData;
			if (storeType === "shop") {
				data = await service.fetchProductFromShopById_data(id, variant_id);
			} else {
				data = await service.fetchById_data(id, storeType);
				variantData = variant_id
					? await variantService.fetchRealById_data(id, variant_id)
					: {};
			}

			dispatch({
				type: actionConstants.FETCH_BY_ID_SUCCESS,
				payload: data,
			});
			if (variant_id) {
				dispatch({
					type: variantConstants.FETCH_BY_ID_SUCCESS,
					payload: variantData,
				});
			}
		} catch (err: any) {
			dispatch({
				type: actionConstants.FETCH_BY_ID_FAILURE,
				error: true,
				payload: err,
			});
		}
	};

export const fetchVariantById = (id: any) => async (dispatch: any) => {
	try {
		const data = await variantService.fetchVariantById(id);
		dispatch({
			type: variantConstants.FETCH_BY_ID_SUCCESS,
			payload: data.result.sync_variant,
		});
	} catch (err: any) {
		console.log(err);
	}
};

export const resourceAddedToCart = (id: any) => {
	return {
		type: actionConstants.ADDED_TO_CART,
		payload: id,
	};
};

export const resourceRemovedFromCart = (id: any) => {
	return {
		type: actionConstants.REMOVED_FROM_CART,
		payload: id,
	};
};

export const resourceAllRemovedFromCart = (id: any) => {
	return {
		type: actionConstants.ALL_REMOVED_FROM_CART,
		payload: id,
	};
};

export const variantAddedToCart = (
	id: any,
	dimension: string,
	price: number,
	shipping_price: number,
	color: any
) => {
	return {
		type: variantConstants.ADDED_TO_CART,
		payload: {
			id,
			dimension,
			price,
			shipping_price,
			color,
		},
	};
};

export const variantRemovedFromCart = (id: any, dimension: string) => {
	return {
		type: variantConstants.REMOVED_FROM_CART,
		payload: {
			id,
			dimension,
		},
	};
};

export const variantAllRemovedFromCart = (id: any, dimension: string) => {
	return {
		type: variantConstants.ALL_REMOVED_FROM_CART,
		payload: {
			id,
			dimension,
		},
	};
};

export const clearAllVariantsFromCart = () => {
	return {
		type: variantConstants.CLEAR_ALL_REMOVED_FROM_CART,
		payload: {},
	};
};
