/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";

import styles from "./ProductDetails.module.css";
import cn from "classnames";
import {
	AccordionItem,
	AccordionWrapper,
	Button,
	DiscountBadge,
	Htag,
	P,
	ProductSlider,
} from "common";
import { ReactComponent as ShareIcon } from "common/Icons/share.svg";
import { ReactComponent as WishlistIcon } from "common/Icons/heart.svg";
import { Select } from "common/Select/Select";
import AddToCartButton from "./AddToCartButton";
import WishlistButton from "components/WishlistButton/WishlistButton";
import { toastMessage } from "airedux/helpers";
import ReactGA from "react-ga";
import { serviceConfig } from "configs";

const ProductDetails = ({ resource, isShop = false }: any) => {
	const [selectedDimension, setSelectedDimension] = useState<any>();
	const [selectedDimensionPrice, setSelectedDimensionPrice] = useState();
	const [selectedDimensionShippingPrice, setSelectedDimensionShippingPrice] =
		useState();
	const [selectedDimensionSalePrice, setSelectedDimensionSalePrice] =
		useState();

	const [selectedColor, setSelectedColor] = useState<any>();
	const [selectedLabel, setSelectedLabel] = useState<string | undefined>();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const search_params = new URLSearchParams(window.location.search);
	const product_id: any = search_params.get("product_id");

	const filtered_resource = isShop
		? resource
		: resource.products.find(
				(product: any) => product.product_id === product_id
		  );
	console.log("FILTERED RESOURCE", filtered_resource);
	console.log("COLOR", selectedColor);
	useEffect(() => {
		const sortedDimensions = filtered_resource.product_dimensions.sort(
			(a: any, b: any) =>
				parseFloat(a.value.replace(/[^\d.]/g, "")) -
				parseFloat(b.value.replace(/[^\d.]/g, ""))
		);
		const sortedSizes = filtered_resource.product_sizes.sort(
			(a: any, b: any) =>
				parseFloat(a.value.replace(/[^\d.]/g, "")) -
				parseFloat(b.value.replace(/[^\d.]/g, ""))
		);
		// Set the default selected dimension or size to the smallest dimension or size
		if (sortedDimensions[0]) {
			setSelectedDimension(sortedDimensions[0].value);
			setSelectedDimensionPrice(sortedDimensions[0].price);
			setSelectedDimensionShippingPrice(sortedDimensions[0].shipping_price);
			setSelectedDimensionSalePrice(sortedDimensions[0].sale_price);
		} else if (sortedSizes[0]) {
			setSelectedDimension(sortedSizes[0].value);
			setSelectedDimensionPrice(sortedSizes[0].price);
			setSelectedDimensionShippingPrice(sortedSizes[0].shipping_price);
			setSelectedDimensionSalePrice(sortedSizes[0].sale_price);
		}
	}, [filtered_resource]);

	useEffect(() => {
		if (
			filtered_resource.product_colors &&
			filtered_resource.product_colors.length > 0
		) {
			setSelectedColor(
				isShop
					? filtered_resource.product_colors[0].color
					: filtered_resource.product_colors[0].color.value
			);
		}
	}, [filtered_resource]);

	const handleShare = async () => {
		ReactGA.event({
			category: "ShareProductURL",
			action: "ShareProductURL",
			label: "Share Product URL",
			value: 0,
		});
		try {
			await navigator.clipboard.writeText(window.location.href);
			toastMessage({
				type: "success",
				message: "Copied!",
				position: "center-top",
			});
			await navigator.share({ url: window.location.href });
		} catch (error) {
			console.error("Error sharing link: ", error);
		}
	};

	useEffect(() => {
		const sortedDimensions = filtered_resource.product_dimensions.sort(
			(a: any, b: any) =>
				parseFloat(a.value.replace(/[^\d.]/g, "")) -
				parseFloat(b.value.replace(/[^\d.]/g, ""))
		);
		const sortedSizes = filtered_resource.product_sizes.sort(
			(a: any, b: any) =>
				parseFloat(a.value.replace(/[^\d.]/g, "")) -
				parseFloat(b.value.replace(/[^\d.]/g, ""))
		);
		// Set the default selected dimension or size to the smallest dimension or size
		if (sortedDimensions[0]) {
			setSelectedDimension(sortedDimensions[0].value);
			setSelectedLabel(`Select Dimensions`);
			setSelectedDimensionPrice(sortedDimensions[0].price);
			setSelectedDimensionShippingPrice(sortedDimensions[0].shipping_price);
			setSelectedDimensionSalePrice(sortedDimensions[0].sale_price);
		} else if (sortedSizes[0]) {
			setSelectedDimension(sortedSizes[0].value);
			setSelectedLabel(`Select Size`);
			setSelectedDimensionPrice(sortedSizes[0].price);
			setSelectedDimensionShippingPrice(sortedSizes[0].shipping_price);
			setSelectedDimensionSalePrice(sortedSizes[0].sale_price);
		}
	}, [filtered_resource]);

	const handleDimensionChange = (e: any) => {
		const selectedOption = e.target.options[e.target.selectedIndex].text;
		const selectedDimension = filtered_resource.product_dimensions.find(
			(dimension: any) => dimension.value === e.target.value
		);
		const selectedSize = filtered_resource.product_sizes.find(
			(size: any) => size.value === e.target.value
		);

		if (selectedOption.startsWith("Select")) {
			setSelectedLabel(selectedOption);
			setSelectedDimension(undefined);
			setSelectedDimensionPrice(undefined);
			setSelectedDimensionShippingPrice(undefined);
			setSelectedDimensionSalePrice(undefined);
		} else if (selectedDimension) {
			setSelectedDimension(selectedDimension.value);
			setSelectedLabel(selectedOption);
			setSelectedDimensionPrice(selectedDimension.price);
			setSelectedDimensionShippingPrice(selectedDimension.shipping_price);
			setSelectedDimensionSalePrice(selectedDimension.sale_price);
		} else if (selectedSize) {
			setSelectedDimension(selectedSize.value);
			setSelectedLabel(selectedOption);
			setSelectedDimensionPrice(selectedSize.price);
			setSelectedDimensionShippingPrice(selectedSize.shipping_price);
			setSelectedDimensionSalePrice(selectedSize.sale_price);
		}
	};

	const handleColorChange = (e: any) => {
		const selectedOption = e.target.options[e.target.selectedIndex].text;
		const selectedColorObj = filtered_resource.product_colors.find(
			(colorObj: any) =>
				isShop ? colorObj.color : colorObj.color.value === e.target.value
		);

		if (selectedOption.startsWith("Select")) {
			setSelectedLabel(selectedOption);
			setSelectedColor(undefined);
		} else if (selectedColorObj) {
			setSelectedColor(
				isShop ? selectedColorObj.color : selectedColorObj.color.value
			);
			setSelectedLabel(selectedOption);
		}
	};

	const renderSelect = (label: any, items: any, handleChange?: any) => {
		return (
			<Select
				label={label}
				defaultSelect={
					label === "Dimensions" || label === "Size"
						? `Select ${label}`
						: label === "Style"
						? undefined
						: items[0]?.value
				}
				required
				onChange={handleChange}
			>
				{items?.map((item: any) => {
					// If the label is "Style", which means we're dealing with colors,
					// use the color property to fetch the values
					if (label === "Style") {
						return (
							<option
								key={item._id}
								value={isShop ? item.color : item.color.value}
							>
								{isShop ? item.color : item.color.value}
							</option>
						);
					} else {
						return (
							<option key={item.id} value={item.value}>
								{`${item.value} ${label === "Dimensions" ? "inch" : ""}`}
							</option>
						);
					}
				})}
			</Select>
		);
	};

	const AddToCartButtonModified = () => {
		const isDisabled = selectedLabel?.startsWith("Select");

		const handleClick = () => {
			if (isDisabled) {
				toastMessage({
					type: "warning",
					message: "Please select all requirements before proceeding.",
					position: "center-top",
				});
			} else {
				// Add to cart logic here
			}
		};

		if (isDisabled) {
			return <AddToCartButton onClick={handleClick} />;
		}

		return (
			<AddToCartButton
				variantId={filtered_resource.product_id}
				selectedDimension={selectedDimension}
				selectedDimensionPrice={selectedDimensionPrice}
				selectedDimensionShippingPrice={selectedDimensionShippingPrice}
				selectedColor={selectedColor}
			/>
		);
	};

	if (!filtered_resource) return null;

	const determineImageSrc = (variant: any, selectedColor: any) => {
		console.log("VARIANT", variant);
		// If there's a selected color, use its image
		if (selectedColor) {
			const colorDetail = variant.product_colors.find((color: any) =>
				isShop ? color.color : color.color.value === selectedColor
			);
			if (colorDetail) {
				return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${colorDetail.image}`;
			}
		}
		// If there's no selected color or the selected color doesn't have an image, use the default image
		return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_image}`;
	};

	return (
		<div className={cn(styles.productdetails)}>
			<div className={cn(styles.firstcol)}>
				<div className={cn(styles.imagediv)}>
					<ProductSlider
						images={[
							determineImageSrc(filtered_resource, selectedColor),
							`${
								isShop
									? `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image}`
									: `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image_2}`
							}`,
							filtered_resource.product_image_3 &&
								`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image_3}`,
							filtered_resource.product_image_4 &&
								`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image_4}`,
							filtered_resource.product_image_5 &&
								`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image_5}`,
						].filter(Boolean)}
					/>
				</div>
				<div className={cn(styles.secondcol)}>
					<div className={cn(styles.information)}>
						<div className={cn(styles.title)}>
							<Htag tag="h3">{filtered_resource.product_name}</Htag>
							<Button
								appearance="ghost-white"
								className={cn(styles.button)}
								onClick={handleShare}
							>
								<ShareIcon />
							</Button>
						</div>
						<div className={cn(selectedDimensionSalePrice && styles.price)}>
							{selectedLabel &&
							(selectedLabel.startsWith("Select") ||
								filtered_resource.product_colors.some(
									(colorObj: any) => colorObj.color.value === selectedLabel
								)) ? (
								<div className={cn(styles.pricerange)}>
									<Htag tag="h4">
										{Math.min(
											...filtered_resource.product_dimensions.map((item: any) =>
												parseFloat(item.price)
											),
											...filtered_resource.product_sizes.map((item: any) =>
												parseFloat(item.price)
											)
										)}
										{filtered_resource.product_currency}
									</Htag>
									<Htag tag="h4">-</Htag>
									<Htag tag="h4">
										{Math.max(
											...filtered_resource.product_dimensions.map((item: any) =>
												parseFloat(item.price)
											),
											...filtered_resource.product_sizes.map((item: any) =>
												parseFloat(item.price)
											)
										)}
										{filtered_resource.product_currency}
									</Htag>
								</div>
							) : (
								selectedDimensionSalePrice && (
									<>
										<DiscountBadge
											appearance="squared"
											className={cn(styles.itemone)}
										>
											-30%
										</DiscountBadge>
										<div className={cn(styles.itemtwo)}>
											<P size="b1line" className={cn(styles.discount)}>
												{selectedDimensionPrice}
												{filtered_resource.product_currency}
											</P>
											<Htag tag="h4">
												{selectedDimensionSalePrice}

												{filtered_resource.product_currency}
											</Htag>
										</div>
									</>
								)
							)}
							{/* <Htag tag="h3">
								{selectedDimensionPrice || filtered_resource.product_price}{" "}
								{filtered_resource.product_currency}
							</Htag> */}
						</div>

						{filtered_resource.product_dimensions &&
							filtered_resource.product_dimensions.length > 0 &&
							renderSelect(
								"Dimensions",
								filtered_resource.product_dimensions.sort(
									(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
								),
								handleDimensionChange
							)}

						{filtered_resource.product_sizes &&
							filtered_resource.product_sizes.length > 0 &&
							renderSelect(
								"Size",
								filtered_resource.product_sizes.sort(
									(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
								),
								handleDimensionChange
							)}
						{filtered_resource.product_colors &&
							filtered_resource.product_colors.length > 0 &&
							renderSelect(
								"Style",
								filtered_resource.product_colors,
								handleColorChange
							)}
					</div>
					<div className={cn(styles.icondiv)}>
						<AddToCartButtonModified />

						<WishlistButton
							id={filtered_resource.product_id}
							data_type="variant"
							button_styles={cn(styles.wishlist)}
							component_type="button"
						/>
					</div>
				</div>
				<div className={cn(styles.descriptions)}>
					{filtered_resource.product_specification && (
						<AccordionWrapper>
							<AccordionItem title={<Htag tag="h4">Specifications</Htag>}>
								{filtered_resource.product_specification.map(
									(spec: any, idx: any) => (
										<div key={spec._id}>
											<P size="b1bold">{spec.title}</P>
											<ul>
												{spec.items.map((item: any, itemIdx: number) => (
													<li key={itemIdx}>
														<P size="p1">{item}</P>
													</li>
												))}
											</ul>
										</div>
									)
								)}
							</AccordionItem>
						</AccordionWrapper>
					)}

					{filtered_resource.product_desc && (
						<AccordionWrapper>
							<AccordionItem
								title={<Htag tag="h4">Description</Htag>}
								accordionOpened={false}
							>
								{filtered_resource.product_desc.map((desc: any) => (
									<div key={desc._id} style={{ marginBottom: "1rem" }}>
										<P size="b1bold">{desc.title}</P>
										<ul>
											{desc.items.map((item: any, idx: number) => (
												<li key={idx}>
													<P size="p1">{item}</P>
												</li>
											))}
										</ul>
									</div>
								))}
							</AccordionItem>
						</AccordionWrapper>
					)}

					{filtered_resource.product_shipping_returns && (
						<AccordionWrapper>
							<AccordionItem
								title={<Htag tag="h4">Shipping & Returns</Htag>}
								accordionOpened={false}
							>
								{filtered_resource.product_shipping_returns.map(
									(shipReturn: any) => (
										<div key={shipReturn._id}>
											<P size="b1bold">{shipReturn.title}</P>
											<ul>
												{shipReturn.items.map((item: any, idx: number) => (
													<li key={idx}>
														<P size="p1">{item}</P>
													</li>
												))}
											</ul>
										</div>
									)
								)}
							</AccordionItem>
						</AccordionWrapper>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductDetails;
